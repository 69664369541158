.toolbar {
  display: flex;
  padding: .8rem 0.2rem;

  button {
    align-items: center;
    background: var(--toolbar-button-bg);
    border: none;
    border-radius: 3px;
    color: var(--text);
    cursor: pointer;
    display: flex;
    flex: 0 0 3.6rem;
    font-size: 1.8rem;
    margin: 0 .6rem;
    max-width: 3.6rem;
    outline: none;
    padding: .9rem;

    &:hover {
      background: var(--primary-light);
      color: #fff;
    }
  }
}

.slim {
  flex-direction: column;
  padding: 0;

  button {
    margin: .8rem 0;
  }
}
