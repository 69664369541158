@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

html {
  box-sizing: border-box;
  font-size: 62.5%;
  scroll-behavior: smooth;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

body {
  color: #000;
  font-family: 'Open Sans', Helvetica, sans-serif;
  font-size: 1.6rem;
  overflow-x: hidden;
}

ol, ul {
  list-style-type: none;
}

button, input, textarea, select, option {
  font-family: 'Open Sans', Helvetica, sans-serif;
}

img {
  max-width: 100%;
  height: auto;
}

/* React Modal */
.ReactModalPortal > div {
  opacity: 0;
}

.ReactModalPortal .ReactModal__Overlay {
  align-items: center;
  display: flex;
  justify-content: center;
  transition: opacity 200ms ease-in-out;
}

.ReactModalPortal .ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModalPortal .ReactModal__Overlay--before-close {
  opacity: 0;
}

// Material UI Popover Reset
.MuiPaper-root {
  background-color: transparent !important;
}

// Scrollbars

::-webkit-scrollbar {
  cursor: pointer;
  width: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  width: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
