.content {
  background-color: var(--dropdown-bg);
  border: 1px solid var(--sidebar-borders);
  box-shadow: 0 10px 15px var(--modal-shadow), 0 5px 5px var(--modal-shadow);
  color: var(--text);
  padding: .4rem 0;
}

.contentSm {
  @extend .content;
  min-width: 0;
  padding: .8rem 0;
}

.link {
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 1.2rem 2.4rem;
  text-decoration: none;
  transition: background-color .1s;

  svg {
    color: var(--text-secondary);
    margin-right: 1rem;
  }

  &:hover { 
    background-color: var(--dropdown-hover);
  }
}

.item {
  padding: .6rem 2.4rem;
}

.select {
  background: var(--dropdown-select);
  border-color: var(--sidebar-borders);
  border-radius: 3px;
  color: var(--text);
  font-size: 1.4rem;
  outline-color: var(--primary-light);
  padding: .6rem;
  width: 100%;
}

.destructive {
  &:hover {
    background-color: var(--error);
    color: var(--on-dark);

    svg {
      color: var(--on-dark);
    }
  }
}

.showDropdown { display: block; }
