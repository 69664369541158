.tools {
  align-items: center;
  border-bottom: 1px solid var(--sidebar-borders);
  display: flex;
  justify-content: flex-end;
  padding: .8rem;

  button {
    align-items: center;
    background: none;
    border: none;
    color: var(--text-secondary);
    cursor: pointer;
    display: flex;
    font-size: 1.4rem;
    outline: none;
  }
}

.title {
  font-size: 1.4rem;
  outline: none;
  padding: .2rem 1rem;
  width: 100%;
}

ul.menu {
  flex-shrink: 0;
  outline: none;
  padding: .2rem 0;

  li {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 1.4rem;
    padding: .4rem 4rem .4rem 1rem;

    svg {
      margin-right: 1.6rem;
    }

    &:hover {
      background: var(--dropdown-hover);
    }
  }
}

.test {
  color: red
}