.add {
  align-items: center;
  background: none;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  display: flex;
  font-size: 1.4rem;
  outline: none;
  padding: 1rem .8rem;
  text-align: left;
  width: 100%;

  &:hover {
    color: var(--primary-light);
  }

  svg {
    margin-right: 1rem;
  }
}

.addForm {
  padding: 1rem .8rem;
  
  input {
    border: 1px solid var(--sidebar-borders);
    border-radius: 3px;
    font-size: 1.6rem;
    max-width: 16rem;
    outline: none;
    padding: .4rem .2rem;
    width: 100%;
  }
}

.error {
  color: var(--error);
  display: block;
  font-size: 1.3rem;
  padding: 1rem .8rem;
}