.notePage {
  background: var(--surface);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: scroll;
  padding: 2rem 0 2rem 2rem;
}

.notePagePreview {
  @extend .notePage;
  background: var(--preview-bg);
}

.title {
  display: flex;
  justify-content: space-between;
  padding-right: 2rem;
  width: 100%;

  input {
    background: var(--surface);
    border: none;
    border-bottom: 1px solid var(--sidebar-borders);
    color: var(--text);
    flex-grow: 1;
    font-size: 3.6rem;
    font-weight: 300;
    outline: none;
    padding-bottom: .6rem;
  }
}

.updated {
  align-items: center;
  display: flex;
  font-size: 1.2rem;
  margin-top: .6rem;

  svg {
    margin-right: .6rem;
  }
}

textarea.editor {
  background: var(--surface);
  border:none;
  color: var(--text);
  flex-grow: 1;
  font-family: 'Fira Code', 'Courier New', Courier, monospace;
  font-size: 1.6rem;
  margin-top: 2rem;
  outline: none;
  overflow-y: scroll;
  padding-right: 2rem;
  resize: none;
  width: 100%;
  word-wrap: break-word;
}

.preview {
  color: var(--text);
  padding-right: 2rem;

  .previewTitle {
    font-size: 3.6rem;
    font-weight: 400;
    padding-bottom: .6rem;
  }

  a {
    color: var(--secondary);
    text-decoration: none;
  }

  h1, h2, h3, h4, h5, h6, ul, ol, p {
    font-size: 1.6rem;
    font-weight: 400;
    padding-bottom: 1.6rem;
  }

  h1, h2, h3, h4, h5, h6 {
    color: var(--preview-headings);
  }

  h1 {
    font-size: 3.6rem;
  }

  h2 {
    font-size: 3.2rem;
  }

  h3 {
    font-size: 2.8rem;
  }

  h4 {
    font-size: 2.4rem;
  }

  h5 {
    font-size: 2rem;
  }

  h6 {
    font-size: 1.6rem;
    font-weight: bold;
  }

  ul {
    list-style-type: disc;
    margin-left: 2rem;

    li {
      ul {
        list-style-type: circle;
        padding-bottom: 0;

        li {
          ul {
            list-style-type: square;
          }
        }
      }
    }
  }

  ol {
    list-style-type: decimal;
    margin-left: 2rem;

    li {
      ol {
        list-style-type: lower-alpha;
        padding-bottom: 0;

        li {
          ol {
            list-style-type: lower-roman;
          }
        }
      }
    }
  }

  ::marker {
    color: var(--text-secondary);
  }

  img {
    padding: 1.6rem;
  }

  blockquote {
    border-left: 2px solid var(--text);
    margin: 0 0 1.6rem;
    padding: 1.6rem 1.6rem 0 3.2rem;
  }

  code {
    background-color: var(--code-bg);
    font-family: 'Fira Code', 'Courier New', Courier, monospace;
    font-size: 1.4rem;
    padding: .2rem .4rem;
  }

  pre {
    background-color: var(--code-bg);
    border: 1px solid var(--block-code-border);
    padding: 1.2rem;

    code {
      padding: 0;
    }
  }
}

.previewButton {
  font-size: 1.2rem !important;
  font-weight: 400 !important;
  opacity: .75;
  position: absolute;
  right: 1rem;
  top: 1rem;
  transition: none !important;
  width: 7rem;
}

.getStarted {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;

  h2 {
    font-size: 3.2rem;
  }
  p {
    font-size: 2rem;
    margin-top: 2rem;

    button {
      background: none;
      border: none;
      color: var(--primary);
      cursor: pointer;
      font-size: 2rem;

      &:hover {
        color: var(--primary-dark);
      }
    }
  }
}
