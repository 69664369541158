.pageList {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  width: 30rem;

  &:hover {
    overflow-y: auto;
  }
}
