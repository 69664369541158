.sidebar {
  border-right: 1px solid var(--sidebar-borders);
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  width: 20rem;
  
  &:hover {
    overflow-y: auto;
  }
}
