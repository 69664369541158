.button {
  background: var(--primary);
  border: none;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1;
  outline: none;
  padding: .8rem 1.2rem;
  text-decoration: none;
  transition: background-color .2s ease, color .2s ease;

  &:hover:enabled {
    background: var(--primary-dark);
  }
}

.destructive {
  background: var(--error);

  &:hover:enabled {
    background: var(--error-dark);
  }
}
