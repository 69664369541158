.container {
  display: flex;
  flex-direction: column;
}

.header {
  color: var(--text-secondary);
  font-size: 1.1rem;
  font-weight: 600;
  margin-top: 2rem;
  padding: 1rem .8rem;
  text-transform: uppercase;
}

.sections {
  flex-grow: 1;
  overflow-y: auto;
}
