.container {
  background: var(--app-sidebar);
  border-right: 1px solid var(--sidebar-borders);
  display: flex;
  flex-shrink: 0;
  font-size: 1.4rem;
  overflow: hidden;
  width: 50rem;
  transition: width .2s;
}

.slim {
  justify-content: center;
  width: 5.2rem;
}

.contents {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.lists {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow: hidden;
}
