.loader {
  align-items: center;
  color: var(--text);
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  width: 100vw;

  .loadingMsg {
    margin: 2rem 0 10rem;
  }
}
