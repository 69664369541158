.item {
  cursor: pointer;
  display: flex;
  transition: background .05s;

  &:hover {
    background: var(--item-hover-bg);
  }
}

.activeItem {
  @extend .item;
  background: var(--item-active-bg);

  &:hover {
    background: var(--item-active-bg);
  }
}

.borderbtm {
  border-bottom: 1px solid var(--sidebar-borders);
}

.titleContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  padding: 1rem .8rem;
}

.xlpad {
  padding-bottom: 1.6rem;
  padding-top: 1.6rem;
}

.title {
  align-items: center;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  font-size: 1.4rem;

  .icon {
    color: var(--text-secondary);
    display: flex;
    flex: 0 0 24px;
  }

  .text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 13rem;
  }

  .textWide {
    width: 20rem;
  }

  input {
    border: 1px solid var(--sidebar-borders);
    border-radius: 3px;
    font-size: 1.4rem;
    outline: none;
    width: 100%;
  }
}

.menu {
  color: var(--text-secondary);
  cursor: pointer;
  display: flex;
  margin: 1rem 1rem 0 0;
  transition: opacity .1s;
  width: 16px;
}

.category {
  align-items: center;
  color: var(--text-secondary);
  display: flex;
  font-size: 1.2rem;
  margin-top: .3rem;
  padding-left: 2.4rem;

  svg {
    margin: 0 .6rem .1rem 0;
  }
}