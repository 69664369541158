.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;

  .title {
    h2 {
      font-size: 2.4rem;
      font-weight: 600;
      margin: 0;
      padding: 0;
    }
  }

  .close {
    align-items: center;
    color: var(--text-secondary);
    cursor: pointer;
    display: flex;
    font-size: 2.4rem;
    padding: .4rem;

    &:hover {
      background: var(--settings-hover);
    }
  }
}

.body {
  font-size: 1.6rem;
  max-height: 1000px;
  transition: max-height .2s ease;

  p {
    margin:0 0 1.2rem 0;
  }

  ul {
    margin:0 0 1.2rem 0;
    padding-left: 1.8rem;
  }
}

.userInfo {
  display: flex;
  width: 50rem;

  .name {
    font-weight: bold;
  }

  .email {
    color: var(--text-secondary);
    margin-top: .6rem;
  }
}

.photo {
  align-items: center;
  background: var(--settings-photo);
  border-radius: 8px;
  display: flex;
  height: 10rem;
  justify-content: center;
  width: 10rem;

  img {
    border-radius: 8px;
    max-width: 10rem;
    width: 100%;
  }
}

.user {
  margin-left: 4rem;
}

.logout {
  margin-left: auto;
}

.options {
  margin-top: 4rem;

  h4 {
    font-size: 1.6rem;
    font-weight: 600;
    margin: 0;
    padding: 0;
  }
}

.option {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: .2rem;

  select {
    background: var(--dropdown-select);
    border-color: var(--sidebar-borders);
    color: var(--text);
    font-size: 1.4rem;
    outline: none;
    padding: .6rem;
  }
}
