.container {
  padding: .8rem;
}

.searchField {
  align-items: center;
  background: var(--search-bg);
  border: 1px solid var(--sidebar-borders);
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.2rem;

  input {
    background: var(--search-bg);
    color: var(--text);
  }

  button {
    align-items: center;
    background: #fff;
    border: none;
    color: var(--text-secondary);
    cursor: pointer;
    display: flex;
    flex: 0 0 1.6rem;
    outline: none;
    padding: 0;
  }
}

.search {
  border: none;
  font-size: 1.4rem;
  outline: none;
  padding: 0;
  width: 100%;
}

.message {
  color: var(--text-secondary);
  font-size: 1.2rem;
  height: 1.8rem;
  padding: 0 1.2rem;
  font-style: italic;
}
